export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2];

export const dictionary = {
		"/": [3],
		"/add-book": [4],
		"/auth": [5],
		"/book/[id]": [6],
		"/delete-book/[id]": [7],
		"/edit-book/[id]": [8],
		"/reading-list": [9],
		"/signin": [10,[2]],
		"/signout": [11],
		"/word-tracker": [12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';